<!-- 对话模型选择卡片模板 -->
<template>
  <div class='cardContent' @click="chooseCard(brainDetail)">
    <div class="carTop">
      <div class="topLeftLogo">
        <img :src="brainDetail.head_photo" alt="">
      </div>
      <div class="title">{{ brainDetail.staff_name }}</div>
    </div>
    <div class="topRightDetile">
      <span class="describe"> {{ brainDetail.duty_desc }}</span>
    </div>
    <div class="carFoot" v-if="showFoot" @click.stop="markedTip(brainDetail.marked_words)">
      {{ brainDetail.marked_words }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'choosenModelCard',
  components: {},
  props: {
    showFoot: {
      // 是否展示卡片底部
      default: true
    },
    brainDetail: {
      require
    }
  },
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 选择对话模式卡片
    chooseCard (type) {
      this.$emit('getModelTalk', type)
    },
    // 展示提示词
    markedTip (word) {
      this.$emit('showTipWords', word)
    }
  },
  created () { },
  mounted () {
    // console.log('brainDetail', this.brainDetail)
  }
}
</script>

<style scoped lang="less">
.cardContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  // border: 2px solid #01A6FB;
  border: 1px solid #E6E6E6;
  cursor: pointer;

  &:hover {
    border-color: #01A6FB;
  }

  .carTop {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      line-height: 35px;
      margin-left: 10px;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #4D4D4D;
      text-align: left;
      width: 290px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .topLeftLogo {
      width: 35px;
      height: 35px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2), inset 0px 2px 4px 0px #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      border: 2px solid #FFFFFF;
      // padding: 2px;
      box-sizing: border-box;

      // background-color: blue;
      img {
        border-radius: 10px 10px 10px 10px;
        width: 100%;
      }

    }

  }

  .topRightDetile {
    width: 100%;
    text-align: left;
    height: 40px;
    .describe {
      text-align: left;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      // 只要超过宽度就换行，不论中文还是英文
      word-break: break-all;
      //最多展示两行
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      //根据样式设置
      line-height: 1.2em;
      max-height: 50px;
    }
  }

  .carFoot {
    text-align: left;
    background: #F2F2F2;
    border-radius: 10px 10px 10px 10px;
    line-height: 40px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}</style>
