<!-- 新增智囊团Brain Trust -->
<template>
  <div class='brainContent'>
    <div class="topHeadBrain">
      <div class="headTitle">智囊团</div>
      <div class="searchInput">
        <el-input placeholder="请输入参谋名" v-model="searchQuery" @keyup.enter.native="searchBrainByName()" clearable @input="searchBrainByName()">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="searchBrainByName"></i>
        </el-input>
      </div>
    </div>
    <div class="midPanel">
      <el-tabs v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane :label="item.tag_name" :name="item.tag_name" v-for="(item,index) in tagList" :key="index" :data-item="JSON.stringify(item)" class="paneTab">
          <choosenModelCard class="innerBrainLabel" :showFoot="false" v-for="(item,index) in brainList" :key="index" :brainDetail="item"  @getModelTalk="addBrainDialog" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import choosenModelCard from '../choosenModelCard/index.vue'
import { getBrainListByTag, getBrainTagList, addNewChatRoom } from '@/api/juewuApi.js'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'addBrainTrust',
  components: { choosenModelCard },
  props: {},
  data () {
    return {
      searchQuery: '',
      activeName: '全部',
      tagList: [], // 属性标签
      brainList: [],
      nowTagId: ''
    }
  },
  watch: {},
  computed: {
    ...mapState('juewuStore', ['allHistoryChatRoom', 'activeChatNum', 'activeBrainNum', 'allBrainChatRoom'])
  },
  methods: {
    ...mapMutations('juewuStore', ['getAllHistoryRoom', 'changeActiveNum', 'changeBrainActiveNum', 'changeNowPanlActiveName']),
    handleClick (tab, event) {
      // 切换tabs数据
      const everyTabParams = JSON.parse(tab.$attrs['data-item'])
      // console.log('everyTabParams', everyTabParams)
      this.fngetBrainListByTag(everyTabParams._id)
      this.nowTagId = everyTabParams._id
    },
    // 获取标签列表
    async fngetBrainTagList () {
      const res = await getBrainTagList()
      if (res && res.Code === 200) {
        this.tagList = res.Data
        this.tagList.unshift({ _id: '', tag_name: '全部' })
      }
    },
    // 获取全部智囊团
    async fngetBrainListByTag (id) {
      const res = await getBrainListByTag({ tag_id: id })
      // console.log('res全部智囊团', res)
      if (res && res.Code === 200) {
        this.brainList = res.Data
      }
    },
    // 开启智囊团新对话
    addBrainDialog (type) {
      console.log('type', type)
      console.log('this.allBrainChatRoom', this.allBrainChatRoom)
      let sameBrainIndex = ''
      let sameBrainItem = ''
      this.allBrainChatRoom.forEach((item, index) => {
        if (type._id === item.staff_id) {
          sameBrainIndex = index
          sameBrainItem = item
        }
      })
      console.log('sameBrainItem', sameBrainItem)
      console.log('sameBrainIndex', sameBrainIndex)
      if (sameBrainIndex !== '') {
        // 跳转到原有对话
        this.changeBrainActiveNum(sameBrainIndex)
        this.$router.push({
        // name: 'commoneChat',
          path: '/brain-chat?id=' + sameBrainItem._id + '&staff_id=' + sameBrainItem.staff_id
        })
      } else {
        // 新增
        this.fnAddHistoryDialog('', type._id)
      }
      this.changeNowPanlActiveName('brain')
      console.log('sameBrainIndex', sameBrainIndex)
    },
    // 新增智囊团对话房间
    async fnAddHistoryDialog (msg, id) {
      const res = await addNewChatRoom({ dialogu_name: msg, staff_id: id, is_staff: true })
      console.log('新增智囊团对话', res)
      if (res && res.Code === 200) {
        this.$store.dispatch('juewuStore/fngetBrainRoomList', { is_staff: true })
        this.changeBrainActiveNum(0)
        this.$router.push({
        // name: 'commoneChat',
          path: '/brain-chat?id=' + res.Data._id + '&staff_id=' + res.Data.staff_id
        })
      }
    },
    // 搜索参谋
    async searchBrainByName () {
      const res = await getBrainListByTag({ tag_id: this.nowTagId, staff_name: this.searchQuery })
      if (res && res.Code === 200) {
        this.brainList = res.Data
      }
    }
  },
  created () { },
  mounted () {
    this.fngetBrainTagList()
    this.fngetBrainListByTag()
  }
}
</script>

<style scoped lang="less">
.brainContent {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;

  .topHeadBrain {
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      font-size: 22px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }

    .searchInput {
      width: 400px;

      /deep/.el-input__inner {
        background-color: #F2F2F2;
        border: 0;
      }
    }
  }

  .midPanel {
    // width: 100%;
    width: 1540px;
    height: 90%;
    padding: 5px;
    box-sizing: border-box;
    .el-tabs {
      height: 100%;
      width: 100%;
      /deep/.el-tabs__item {
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 500;
        color: #999999;
        min-width: 120px;

      }
      /deep/.el-tabs__item.is-active{
          color: #333;
          font-weight: 800;
        }
        /deep/ .el-tabs__active-bar{
        background-color: #333;
        }
      /deep/.el-tabs__content {
        height: 95%;
      }

      .paneTab {
        width: 100%;
        height: 97%;
        overflow: hidden;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        .innerBrainLabel {
          width: 350px;
          height: 100px;
          margin: 10px 15px;
        }
      }
    }

  }
}</style>
